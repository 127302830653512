import Form from "./Form";


const Home = () => {
  return (
    <>
      <Form />
    </>
  );
}

export default Home; 
